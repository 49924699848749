/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react'
// import styled from 'styled-components';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link } from 'gatsby'
import { usePartnerData } from 'hooks/UsePartnerData'

import { Container, Grid } from '@material-ui/core'

import { Button } from 'components/common'
import Textfield from 'components/common/FormUI/TextField'
// import countries from 'data/countries.json'
import { Center } from './styles'
// import { Debug } from './Debug'

// varaibles for this contact form
const formName = 'cmu-contact v3'
const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  // addressLine1: '',
  // addressLine2: '',
  // city: '',
  // state: '',
  // zip: '',
  // country: '',
  // campaign: 'default',
  success: false,
}

// encoding required for netlify to correctly capture data
const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

const FORM_VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required('Your first name is required'),
  lastName: Yup.string().required('Your last name is required'),
  email: Yup.string().email('Invalid email').required('Your email address is required'),
  phone: Yup.number().integer().typeError('Please enter a valid phone number'),
  zip: Yup.number().integer().typeError('Please enter a valid zip code'),
})

const ContactForm = (props) => {
  const { program, sendTo } = props
  const { shortName, legalName, url } = usePartnerData()

  // const [showMailAddr, setshowMailAddr] = useState(false)
  // const toggleMailAddr = () => {
  //   setshowMailAddr(!showMailAddr)
  // }

  return (
    <Formik
      initialValues={{ ...INITIAL_FORM_STATE }}
      validationSchema={FORM_VALIDATION_SCHEMA}
      onSubmit={(values, actions) => {
        actions.setFieldValue(values.Program, program)
        const payload = {
          Program: program,
          ...values,
        } // Construct the new payload
        actions.setValues(payload)
        fetch('/contact-static/?no-cache=1', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': formName, ...payload }),
        })
          .then(() => {
            actions.setFieldValue('success', true)
            // navigate(
            //   `/thanks/?campaign=${campaign}&program=${program}&fname=${values.firstName}&lname=${values.lastName}`
            // )
          })
          .catch(() => {
            actions.setSubmitting(false)
            actions.setFieldValue('success', false)
            alert('Error')
          })
          .finally(() => actions.setSubmitting(false))
      }}
    >
      {({ values, isSubmitting }) => (
        <Form name={formName} className="form" data-netlify data-netlify-honeypot="form-topyenoh">
          <input type="hidden" name="form-name" value={formName} />
          <input type="hidden" name="Program" value={program} />
          <input name="form-topyenoh" style={{ display: 'none' }} />
          {/* <input type="hidden" name="Birth date" /> */}
          {!values.success && (
            <div className="form-fields">
              <div className="contact-header">
                <h2>Request Information</h2>
                <p>
                  Ready to learn more about this program? Let’s chat. We’ll help you discover a brighter future at{' '}
                  {shortName}.
                </p>
              </div>
              <Container>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Textfield label="First Name" name="firstName" />
                  </Grid>
                  <Grid item xs={6}>
                    <Textfield label="Last Name" name="lastName" />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield label="Email" name="email" />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield label="Phone" name="phone" />
                  </Grid>
                </Grid>
                {/* <Debug /> */}
                <p className="acknowledgement">
                  By submitting this form, you agree to be contacted by {legalName} via email or phone. We’ll only use
                  your data to provide you with additional information about our programs.
                </p>
                <Center className="type-button">
                  <Button secondary type="submit" disabled={isSubmitting}>
                    Submit
                  </Button>
                </Center>
              </Container>
            </div>
          )}
          {values.success && (
            <div className="thanks-message">
              <h2>Thank You, We’ll Be in Touch</h2>
              <p className="featured">Explore Next Steps at {shortName}</p>
              <p>
                We can’t wait to tell you more about your program of interest. You should expect to hear form us soon.
                Until then, you can explore our website for information regarding admission requirements, courses,
                faculty, and more. Take the next step in your journey with us.
              </p>

              <Link to={sendTo || url} className="button">
                Go to {shortName}'s Website
              </Link>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
